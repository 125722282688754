import { useState } from "react";
import {
  COOKIE_NAME,
  COOKIE_QUERY_NAME,
  deleteCookie,
  gtmRegistration,
  validatePassword,
  POST_REG_FUNNEL_SHOW,
  POST_REG_FUNNEL_SHOW_REQUIRED,
  prepareRegistrationData,
  REGISTRATION_CONFIRMED, simplyClick,
} from "../../helpers";
import { apiRegistration } from "../../services/api";
import ButtonSubmit from "../buttonSubmit";
import InputGlobal from "../inputGlobal";
import Eye from "../../images/eye.svg";
import EyeOff from "../../images/eye-off.svg";
import "./passwordForm.scss";

function PasswordForm({ data, setData }) {
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorPassword, setErrorPassword] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [checkBox, setCheckBox] = useState(false);
  const [termsError, setTermsError] = useState(null);
  const [loader, setLoader] = useState(false);

  const toggleBtn = (e) => {
    e.preventDefault();
    setPasswordShow(!passwordShow);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setResponseError(null);
    const resultPassword = validatePassword(data.password);
    if (!resultPassword.status) {
      setErrorPassword(resultPassword.error);
    } else if (!checkBox) {
      setTermsError("Please accept our Terms of Use, Privacy Policy, Cookie Policy, Billing & Refund Policy, Risks notice, Disclosures & Disclaimers");
    } else {
      setLoader(true);
      apiRegistration(prepareRegistrationData(data), responseCb);
      simplyClick("cl_lnd_lt2_password_join", false);
    }
  };

  const responseCb = (response) => {
    setLoader(false);
    if (response.status === 200) {
      const data = JSON.parse(response.responseText);

      if (data.success === true) {
        deleteCookie(COOKIE_QUERY_NAME);
        deleteCookie(COOKIE_NAME);
        gtmRegistration(data.data);

        if (data.data.onModeration === true) {
          window.location.href = "/moderation/";
        } else {
          if (
            data.data.confirmed == REGISTRATION_CONFIRMED &&
            data.data.access_token
          ) {
            if (
              data.data.reg_funnel_status == POST_REG_FUNNEL_SHOW ||
              data.data.reg_funnel_status == POST_REG_FUNNEL_SHOW_REQUIRED
            ) {
              window.location.href = "/wizard/";
            } else {
              window.location.href = "/app/";
            }
          } else {
            window.location.href = "/confirm/";
          }
        }
      }

      return;
    } else if (response.status === 422) {
      const data = JSON.parse(response.responseText);

      if (data.errors && data.errors.length > 0) {
        let errorText = "";

        for (let i = 0; i < data.errors.length; i++) {
          const error = data.errors[i];

          errorText += i > 0 ? " " + error.message : error.message;
        }

        setResponseError(errorText);
        return;
      }
    } else if (response.status === 403) {
      window.location.href = "/moderation/";
      return;
    }

    setResponseError(
      "Can't register your profile now. Please try again later."
    );
  };

  const handleAddPassword = (e) => {
    setErrorPassword(null);
    setData({ ...data, password: e.target.value });
  };
  const handleCheckBox = (e) => {
    setTermsError(false);
    setCheckBox(!checkBox);
  };

  return (
    <div className="error__form-wrapper">
      {loader && (
        <div className="spin-loader">
          <span>Loading...</span>
        </div>
      )}

      <div className="user__form-top">
        <h3>My password</h3>
      </div>
      <form
        className="password__form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="input__password-wrapper">
          <InputGlobal
            onChange={(e) => handleAddPassword(e)}
            placeholder={"Create your password"}
            className={"input__global input__password"}
            name={"password"}
            type={passwordShow ? "text" : "password"}
          />
          <button onClick={toggleBtn} className="logo__eye">
            {passwordShow ? (
              <img src={Eye} alt="icon" />
            ) : (
              <img src={EyeOff} alt="icon" />
            )}
          </button>
        </div>
        {errorPassword && <p className="errorPassword">{errorPassword}</p>}
        <div className="form-group">
          <input
            checked={checkBox}
            onChange={(e) => handleCheckBox(e)}
            type="checkbox"
            id="remember_me"
            className="checkBox"
            name="remember"
            value="1"
          />
          <label htmlFor="remember_me">
              <span>
              I have read, understand and agree to&nbsp;
                <a href="/terms-of-use/" target="_blank">
                Terms of Use,&nbsp;
              </a>
              <a href="/privacy-policy/" target="_blank">
                Privacy Policy,&nbsp;
              </a>
              <a href="/cookie-policy/" target="_blank">
                Cookie policy,&nbsp;
              </a>
               <a href="/billing-policy/" target="_blank">
                 Billing &&nbsp;
               </a>
               <a href="/refund-policy/" target="_blank">
                 Refund Policy,&nbsp;
               </a>
               <a href="/risks-notice/" target="_blank">
                 Risks notice,&nbsp;
               </a>
               <a href="/disclosures-disclaimers/" target="_blank">
                 Disclosures & Disclaimers
               </a>
            </span>
          </label>
        </div>
        {termsError && <p className="errorPassword">{termsError}</p>}
        {responseError && <p className="errorPassword">{responseError}</p>}
        <ButtonSubmit
          className={"button__global button__password"}
          text={"Join now"}
        />
        <div className="progress__dots">
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
        </div>
      </form>
    </div>
  );
}

export default PasswordForm;
