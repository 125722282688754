import { request } from "./index";
import { EMAIL_VALIDATION_ENDPOINT, REG_ENDPOINT } from "../helpers";

/**
 * Registration user by api
 * @param {Object} data Request data
 * @param {function} cb Callback function
 */
export const apiRegistration = (data, cb) => {
  request(REG_ENDPOINT, data, "POST", cb);
};

/**
 * Send request for validate user unique email
 * @param {{email: string}} data Request data
 * @param {function} cb Callback function
 */
export const apiEmailUniqueValidation = (data, cb) => {
  request(EMAIL_VALIDATION_ENDPOINT, data, "POST", cb);
};