import React from "react";
import "./buttonSubmit.scss";

const ButtonSubmit = ({text, className, onClick, dataAttr}) => {
  return (
    <>
      <button className={className} type="submit" onClick={onClick} data-attr={dataAttr} >
        {text}
      </button>
    </>
  );
};

export default ButtonSubmit;
