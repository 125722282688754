export const request = (url, data, method, cb) => {
    const xmlhttp = new XMLHttpRequest();

    if (method && method === 'POST')
        xmlhttp.open(method, url, true);
    else
        xmlhttp.open('POST', url, true);

    xmlhttp.onreadystatechange = function() {
        if (this.readyState === 4) {
            cb(this);
        }
    };

    xmlhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
    xmlhttp.send(JSON.stringify(data));
};
