import React, { useState } from "react";
import {
  getBirthdayInFormat,
  getDate,
  getMonths,
  getYears, simplyClick,
  validateAge,
} from "../../../src/helpers";
import ButtonSubmit from "../buttonSubmit";

import "./birthdayForm.scss";

function BirthdayForm({ data, setData, nextStep }) {
  const [errorDate, setErrorDate] = useState(null);
  const [birthdayData, setBirthdayData] = useState({});
  const [value, setValue] = useState("default");

  const handlerSubmit = (e) => {
    e.preventDefault();
    const { error, status } = validateAge(
      birthdayData.month,
      birthdayData.day,
      birthdayData.year
    );

    if (!status) {
      setErrorDate(error);
      return;
    }

    setData({
      ...data,
      birthday: getBirthdayInFormat(
        birthdayData.year,
        birthdayData.month,
        birthdayData.day
      ),
    });
    simplyClick("cl_lnd_lt2_date_birth_next", null);
    nextStep();
  };

  const handleAddMonth = (e) => {
    setErrorDate(null);
    setBirthdayData({ ...birthdayData, month: e.target.value });
  };
  const handleAddDay = (e) => {
    setErrorDate(null);
    setBirthdayData({ ...birthdayData, day: e.target.value });
  };
  const handleAddYear = (e) => {
    setErrorDate(null);
    setBirthdayData({ ...birthdayData, year: e.target.value });
  };

  return (
    <div className="promo__wrapper-birthday">
      <div className="user__form-top">
        <h3>My date of birth</h3>
      </div>
      <form className="form__date" onSubmit={handlerSubmit}>
        <div className="date__wrapper">
          <div className="date__wrapper-select">
            <select
              className={birthdayData.month ? "active" : ""}
              onChange={(e) => handleAddMonth(e)}
              defaultValue={value}
            >
              <option value="default" disabled hidden>
                Month
              </option>
              {getMonths().map((item, index) => (
                <option key={index} value={index}>
                  {item || ""}
                </option>
              ))}
            </select>
          </div>
          <div className="date__wrapper-select">
            <select
              className={birthdayData.day ? "active" : ""}
              onChange={(e) => handleAddDay(e)}
              defaultValue={value}
            >
              <option value="default" disabled hidden>
                Day
              </option>
              {getDate().map((item, index) => (
                <option key={index} value={item}>
                  {item || ""}
                </option>
              ))}
            </select>
          </div>
          <div className="date__wrapper-select">
            <select
              className={birthdayData.year ? "active" : ""}
              onChange={(e) => handleAddYear(e)}
              defaultValue={value}
            >
              <option value="default" disabled hidden>
                Year
              </option>
              {getYears().map((item, index) => (
                <option key={index} value={item}>
                  {item || ""}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Вывод ошибки  */}
        {errorDate && <p className="errorDate">{errorDate}</p>}
        <div className={"birthday_confirm"}>
          By proceeding you confirm that you are fully legally capable of using website and major or at least 18 years old
        </div>

        <ButtonSubmit
          className={"button__global button__birthday"}
          text={"NEXT"}
        />
        <div className="progress__dots">
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </form>
    </div>
  );
}

export default BirthdayForm;
