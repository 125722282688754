import React from "react";
import "./InputGlobal.scss";

function InputGlobal({ placeholder, className, name, type, onChange, value }) {
  return (
    <input
      autoComplete="off"
      type={type}
      placeholder={placeholder}
      className={className}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
}

export default InputGlobal;
