import React, { useState } from "react";

import GenderForm from "./components/genderForm";
import BirthdayForm from "./components/birthdayForm";
import EmailForm from "./components/emailForm";
import NameForm from "./components/nameForm";
import PasswordForm from "./components/passwordForm";
import PromoStep from "./components/promoStep";
import {createPortal} from "react-dom";
import {Footer} from "./components/Footer";

function App() {
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(1);
  const footer = document.getElementById("footer");
  let content = null;


  switch (step) {
    case 2:
      content = (
        <>
          <GenderForm
            nextStep={() => setStep(3)}
            setData={setFormData}
            data={formData}
          />
        </>
      );
      break;

    case 3:
      content = (
        <>
          <BirthdayForm
            nextStep={() => setStep(4)}
            setData={setFormData}
            data={formData}
          />
        </>
      );
      break;
    case 4:
      content = (
        <>
          <NameForm
            nextStep={() => setStep(5)}
            setData={setFormData}
            data={formData}
          />
        </>
      );
      break;
    case 5:
      content = (
        <>
          <EmailForm
            nextStep={() => setStep(6)}
            setData={setFormData}
            data={formData}
          />
        </>
      );
      break;
    case 6:
      content = (
        <>
          <PasswordForm
            nextStep={() => setStep(7)}
            setData={setFormData}
            data={formData}
          />
        </>
      );
      break;
    default:
      content = (
        <>
          <PromoStep nextStep={() => setStep(2)} />
        </>
      );
      break;
  }

  return <>
        <div>
            {content}
        </div>
        {createPortal( <Footer/>, footer)}
    </>
}

export default App;
