import React, { useState } from "react";
import "./emailForm.scss";
import {simplyClick, validateEmail} from "../../helpers";
import ButtonSubmit from "../buttonSubmit";
import InputGlobal from "../inputGlobal";

function EmailForm({ data, setData, nextStep }) {
  const [errorEmail, setErrorEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");

  const domains = [
    "@gmail.com",
    "@yahoo.com",
    "@hotmail.com",
    "@aol.com",
    "@outlook.com",
    "@comcast.net",
    "@live.com",
    "@mail.com",
    "@msn.com",
    "@att.net",
    "@icloud.com",
  ];

  const handlerSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    validateEmail(value, (result) => {
      const { status, error } = result;
      setLoader(false);
      if (!status) {
        setErrorEmail(error);
      } else {
        setData({ ...data, email: value });
        simplyClick("cl_lnd_lt2_email_next", null);
        nextStep();
      }
    });
  };
  const handleAddEmail = (e) => {
    setEmailValue(e.target.value);
  };

  const setEmailValue = (value) => {
    setErrorEmail(null);
    setValue(value);
  };

  return (
    <>
      {loader && (
        <div className="spin-loader">
          <span>Loading...</span>
        </div>
      )}
      <div className="email__form-wrapper">
        <div className="user__form-top">
          <h3>My email</h3>
        </div>
        <form
          className="form__email"
          onSubmit={handlerSubmit}
          autoComplete="off"
        >
          <div className="input__wrapper">
            <InputGlobal
              onChange={(e) => handleAddEmail(e)}
              placeholder={"Type your email"}
              className={"input__global input__email"}
              name={"email"}
              type={"text"}
              value={value}
            />
            {value.length > 0 && !value.includes("@") && (
              <div className="autocomplete__box">
                <ul className="autocomplete__box-list">
                  {domains.map((items, index) => (
                    <li
                      onClick={() => setValue(value + items)}
                      className="autocomplete__box-list-item"
                      key={index}
                    >
                      {value}
                      {items}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {errorEmail && <p className="errorEmail">{errorEmail}</p>}
          <ButtonSubmit
            className={"button__global button__email"}
            text={"NEXT"}
          />
          <div className="progress__dots">
            <span className="active"></span>
            <span className="active"></span>
            <span className="active"></span>
            <span className="active"></span>
            <span className="active"></span>
            <span></span>
          </div>
        </form>
      </div>
    </>
  );
}

export default EmailForm;
