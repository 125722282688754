import './Footer.scss';

export const Footer = () => {
  return (
      <>
        <div className="footer__wrapper">
        <div className={"footer-menu"}>
          <div className={"block"}>
            <a href="/terms-of-use/" target={"_blank"} rel="noreferrer">
              Terms of Use
            </a>
            <a href="/privacy-policy/" target={"_blank"} rel="noreferrer">
              Privacy Policy
            </a>
            <a href="/cookie-policy/" target={"_blank"} rel="noreferrer">
              Cookie Policy
            </a>
          </div>
          <div className={"block"}>
            <a href="/refund-policy/" target={"_blank"} rel="noreferrer">
              Refund Policy
            </a>
            <a href="/risks-notice/" target={"_blank"} rel="noreferrer">
              Risks notice
            </a>
            <a href="/billing-policy/" target={"_blank"} rel="noreferrer">
              Billing Policy
            </a>
          </div>
          <div className={"block"}>
            <a href="/disclosures-disclaimers/" target={"_blank"} rel="noreferrer">
              Disclosures and Disclaimers
            </a>
            <a
                href="mailto: support@latinmelodies.com"
                target={"_blank"}
                rel="noreferrer"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
        <div className="cookie__policy-wrap">
          <p className="text">
            We use cookies to make your experience better! If you continue to use
            this site we will assume that you are happy with it.
          </p>
          <button className="accept" data-attr="cookies">Agree & Close</button>
        </div>
      </>
  );
};
