import React from "react";
import { useState, useEffect } from "react";
import ButtonSubmit from "../buttonSubmit";
import {
  CONST_GENDER_FEMALE,
  CONST_GENDER_MALE,
  isValidGender, simplyClick,
} from "../../helpers";

import "./GenderForm.scss";

function GenderForm({ data, setData, nextStep }) {
  const [errorGender, setErrorGender] = useState(null);
  const genderLabel = {
    [CONST_GENDER_MALE]: "I'm a man, looking for a woman",
    [CONST_GENDER_FEMALE]: "I'm a woman, looking   for a man",
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    if (!isValidGender(data.gender)) {
      setErrorGender("Please, choose your gender ");
    } else {
      simplyClick("cl_lnd_lt2_i_am_next", null);
      nextStep();
    }
  };

  const [choiceGender, setChoiceGender] = useState(CONST_GENDER_MALE);

  const handleAddTypeGender = (e) => {
    setData({ ...data, gender: e.target.value.toString() });
    setChoiceGender(e.target.value.toString());
  };

  useEffect(() => {
    setData({ ...data, gender: CONST_GENDER_MALE });
  }, []);

  const options = [];

  Object.keys(genderLabel).forEach((key, index) => {
    options.push(
      <option value={key} key={`${key}_${index}`}>
        {genderLabel[key]}
      </option>
    );
  });

  return (
    <div className="promo__wrapper-gender">
      <form className="gender__form" onSubmit={handlerSubmit}>
        <div className="user__form-top">
          <h3>I am</h3>
        </div>
        <div className="gender__form-content">
          <div className="custom-select gender">
            <div className="custom-select-me">
              <select
                onChange={(e) => handleAddTypeGender(e)}
                className="gender"
                value={choiceGender}
              >
                {options}
              </select>
            </div>
          </div>
          {errorGender && <p className="error">{errorGender}</p>}
          <ButtonSubmit
            className={"button__global button__gender"}
            text={"NEXT"}
          />
          <div className="progress__dots">
            <span className="active"></span>
            <span className="active"></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </form>
    </div>
  );
}

export default GenderForm;
